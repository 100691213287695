.file-container {
    width: 100%;
    border-radius: 20px;
    box-shadow: 1px 2px 2px #3545960d;
    overflow: hidden;
}


.file-container-image {
    position: relative;
}

.file-container-image>img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
}

.file-container-image>a {
    position: absolute;
    right: 8px;
    bottom: 8px;
    padding: 2px;
    border-radius: 5px;
    background-color: #00000088;
}

.file-container-image>a>img {
    filter: invert(1);
}

.file-container-file {
    box-shadow: 1px 2px 2px #3545960d;
}

.file-container-file>a {
    display: block;
    padding: 12px 12px;
    border-radius: 20px;
    border: 1px solid #5ca0f5;
    color: #5ca0f5;
    text-decoration: none;
    text-align: center;
}