.story-container {

}

.story-breadcrumbs {
    margin: 0 0 8px;
}

.story-breadcrumbs>a {
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
    font-size: 20px;
    color: #c7c7c7;
    text-decoration: unset;
}

.story-breadcrumbs>a>img {
    width: 24px;
    margin: 0 8px 0 0;
    opacity: .24;
}

.story-title {
    margin: 0 0 16px 0;
    font-weight: 500;
    font-size: 36px;
    color: #44aae0;
}

.story-city {
    margin: 0 0 44px 0;
    font-size: 20px;
    font-weight: 400;
    color: #c7c7c7;
}

.story-text-title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    
}

.story-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    margin-bottom: 32px;
    background: #F7FCFF;
    border-radius: 20px;
}

.story-text-btn {
    align-self: flex-end;
    border: 0;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    color: #5ca0f5;
    transition: opacity .3s;
}

.story-text-btn:hover {
  opacity: .5;
}