.criterions {
    margin-bottom: 36px;
}

.criterions h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.criterions h3>img {
    transition: transform .5s;
}

.criterions-arrow {
    transform: rotate(0);
}

.criterions-arrow-rotate {
    transform: rotate(-180deg);
}

.criterions>div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    border-radius: 20px;
    transition: background-color .3s;
    cursor: pointer;
    user-select: none;
}

.criterions>div:first-child:hover {
   background-color: #f7fcff;
}

.criterions>div:first-child>div {
    color: #ffad00;
    font-size: 20px;
    font-weight: 600;
}

.criterions>div:last-child {
    margin-top: 36px;
    overflow: hidden;
    transition: all .5s;
}

.criterions-content {
    max-height: 100%;
}

.criterions-content-hidden {
    max-height: 0;
}

.criteria {
    margin-bottom: 32px;
}

.criteria h4 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
}

.criteria>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 1px 2px 7px 0px #3545960d;
}

.criteria>div>div:first-child {
    max-width: 85%;
    margin-right: 32px;
    color: #354596;
    font-size: 20px;
    font-weight: 400;
}

.criteria>div>div:last-child {
    color: #ffad00;
    font-size: 24px;
    font-weight: 600;
}