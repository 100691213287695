.footer {
    min-height: 20vh;
    background-color: #000000;
}

.footer>div {
    min-height: 20vh;
    height: auto;
    margin-bottom: 0;
    padding: 32px 0;
}

.footer-content>div>div {
    display: flex;
    justify-content: center;
}

.footer-support {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-support h3 {
    color: #ffffff;
}

.footer-support a {
    color: #ffffff;
    text-decoration: none;
    transition: opacity .3s;
}

.footer-support a:hover {
    opacity: .75;
}

.footer-links {
    display: flex;
    padding: 8px 16px;
    gap: 24px;
}

.footer-links a {
    transition: opacity .3s;
}

.footer-links a:hover {
    opacity: .75;
}

.footer-links img {
    width: 28px;
}

.footer .footer-logo {
    display: flex;
    justify-content: center;
    margin: 12px 0;
}

.footer .footer-logo a {
    transition: opacity .3s;
}

.footer .footer-logo a:hover {
    opacity: .75;
}

.footer .footer-logo img {
    width: 160px;
    filter: brightness(0) invert(1);
}