.story-contacts {
    margin-bottom: 24px;
    overflow: hidden;
}

.story-contacts>div {
    padding: 32px;
    margin-bottom: 16px;
    border-radius: 20px;
    background: #f7fcff;
    box-shadow: 1px 2px 2px #3545960d;
}

.story-contacts h3 {
    font-size: 24px;
    margin-bottom: 8px;
}

.story-contacts h4 {
    font-size: 18px;
    margin-bottom: 14px;
}

.story-contacts p {
    display: flex;
    align-items: center;
    word-break: break-all;
}

.story-contacts img {
    padding-right: 6px;
    height: 22px;
    width: auto;
}