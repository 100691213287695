.home-page h1 {
    margin-bottom: 36px;
}

.home-page-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

.home-page-text span.yellow {
    color: #ffad00;
}

.home-page-text span.blue {
    color: #5ca0f5;
}

.home-page-text a {
    color: #5ca0f5;
}

.home-page-cover {
    width: 100%;
    margin-bottom: 24px;
}

.home-page-cover img {
    width: 100%;
}

.home-page-btn {
    display: flex;
    justify-content: flex-end;
}

.home-page-login {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-page-login-card {
    max-width: 600px;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 1px 2px 7px #3545960d;
}

.home-page-login-card > h1 {
    margin-bottom: 32px;
    text-align: center;
    font-size: 32px;
}

.home-page-login-card > div {
   display: flex;
   justify-content: center;
   align-items: center;
}

.home-page-login-card .btn {
   width: 200px;
}