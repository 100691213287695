.comments-modal-component {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #282828b3;
    overflow: hidden;
    z-index: 999;
}

.comments-modal-component-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 561px;
    padding: 32px;
    gap: 16px;
    border-radius: 20px;
    background: #FFF;
}

.comments-modal-component-control {
    position: absolute;
    right: 12px;
    top: 12px;
}

.comments-modal-component-control > button {
    padding: 8px;
    border: 0;
    border-radius: 50%;
    background-color: #f7fcff;
}

.comments-modal-component-control > button > img {
    filter: brightness(0);
}

