.files-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.files-list>div {
    margin: 10px 0;
}

.files h3 {
    font-size: 24px;
    margin-bottom: 8px;
}

.files-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 64px;
    margin-bottom: 8px;
    border-radius: 20px;
    background: #f7fcff;
    box-shadow: 1px 2px 2px #3545960d;
}

.files-url {
    box-shadow: 1px 2px 2px #3545960d;
}

.files-url>a {
    display: block;
    padding: 12px 12px;
    border-radius: 20px;
    border: 1px solid #5ca0f5;
    color: #5ca0f5;
    text-decoration: none;
    text-align: center;
}