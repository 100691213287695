.category {
    color: #354596;
}

.category h2 {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
}

.category-content {
    padding: 32px;
    border-radius: 20px;
    background-color: #f7fcff;
    box-shadow: 1px 2px 2px #3545960d;
}

.category-content div {
    display: flex;
    justify-content: space-between;
}

.category-content p {
    margin-bottom: 8px;
    color: #354596;
    font-size: 18px;
    font-weight: 400;
}

.category-inactive {
    
}

.category-inactive-content {
    color: #ababab;
    background-color: #fafafa;
}

.category-inactive .category-inactive-content p {
    color: #ababab;
}

.category-dream {
    
}

.category-dream .category-content {
    color: #ffad00;
    background-color: #fffbf3;
}

.category-dream .category-content p {
    color: #ffad00;
}

.category-content-links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 12px;
    margin-top: 12px;
}

.category-content-links a:last-child {
    border: 1px solid #5ca0f5;
    background-color: transparent;
    color: #5ca0f5;
    transition: all .3s;
}

.category-content-links a:last-child:hover {
    border: 1px solid transparent;
    background-color: #ffffff;
}

.category-dream .category-content-links a {
    color: #ffad00;
}

.category-dream .category-content-links a:first-child {
    background-color: #ffad00;
    color: #ffffff;
}

.category-dream .category-content-links a:last-child {
    border: 1px solid #ffad00;
    background-color: transparent;
    color: #ffad00;
    transition: all .3s;
}

.category-inactive .category-content-links a {
    border-color: #ababab;
    color: #ababab;
}