.comments {
    display: flex;
    flex-direction: column;
}

.comments-title {
    margin-bottom: 44px;
    font-size: 24px;
    font-weight: 600;
}

.comment {
    position: relative;
    width: fit-content;
    display: flex;
    padding: 32px;
    margin-bottom: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    border-radius: 20px 20px 20px 0px;
    background-color: #f7fcff;
    max-width: 100%;
    overflow: hidden;
}

.comment::before {
    position: absolute;
    left: -10px;
    bottom: 0;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color:  transparent transparent #f7fcff transparent;
}

.comment h3 {
    color: #354596;
    font-size: 16px;
    font-weight: 600;
}

.comment h3>span {
    padding-left: 8px;
    color: #ababab;
    font-size: 14px;
    font-weight: 400;
}

.comment > p {
    max-width: 100%;
    overflow: hidden;
}