.select-component {
    margin-bottom: 32px;
}

.select-component-title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
}


.select-component-control {
    padding: 32px;
    border-radius: 20px;
    background-color: #f7fcff;
    box-shadow: 1px 2px 2px #3545960d;
}

.select-component-control .form-select:disabled {
    background-color: #f7fcff;
}