.assessors-control-page h1 {
    margin-bottom: 44px;
    color: #5ca0f5;
    font-size: 36px;
    font-weight: 500;
    line-height: 32px;
}

.assessors-control-page-wrapper {
    display: flex;
    flex-direction: column;
}

.assessors-control-page-wrapper>button {
    align-self: flex-end;
    display: flex;
    padding: 12px 32px;
    justify-content: flex-end;
    align-items: flex-end;
    border-radius: 50px;
    background-color: #5ca0f5;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.assessors-control-page-wrapper>button:hover {
    background-color: #c1e9ff;
}