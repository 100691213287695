.user-list {
    margin-bottom: 40px;
}

.user-list h2 {
    margin-bottom: 16px;
    color: #354596;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.user-list>div {
    padding: 32px;
    border-radius: 20px;
    background-color: #f7fcff;
}

.user-list>div>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-list button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 10px 20px 10px;
    margin-bottom: 20px;
    background-color: #ffffff;
    color: #5ca0f5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    transition: border .3s;
}

.user-list>div>div:last-child>button {
    margin-bottom: 0;
}

.user-list button:hover {
    border: 1px solid #5ca0f5;
}

.user-list button>img {
    width: 20px;
    height: auto;
}