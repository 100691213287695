.user-management-container button {
    justify-content: flex-start;
    width: 100%;
    border: 1px solid transparent;
    background-color: #ffffff;
    color: #5CA0F5;
    font-size: 12px;
    transition: all .3s;
}

.user-management-container table {
    border-color: #124c92;
    table-layout: fixed;
}

.user-management-container tbody tr:nth-child(2n + 1) {
    background-color: #79b3fb07;
}

.user-management-container thead th {
    font-size: 16px;
    color: #354596 !important;
}

.user-management-container tbody td {
    background-color: transparent;
    font-size: 14px;
    color: #354596 !important;
}

.user-management-container :is(th, td) {
    width: 30%;
}

.user-management-container :is(th, td):nth-child(3) {
    width: 25%;
}

.user-management-container :is(th, td):nth-child(4) {
    width: 15%;
}

.user-management-container button:hover {
    border: 1px solid #5ca0f5;
    background-color: transparent;
    color: #5CA0F5;
}

.user-management-container button.active {
    border: 1px solid transparent;
    background-color: #f7fcff;
    color: #354596;
}

.user-management-container button.active:hover {
    border: 1px solid #5ca0f5;
}

.user-management-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
}