.modal-container {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #282828b3;
    overflow: hidden;
}

.modal-window {
    display: flex;
    width: 561px;
    padding: 56px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 44px;
    border-radius: 20px;
    background: #FFF;
}

.modal-window p {
    text-align: center;
}