.assessment-criteria {
    padding: 32px;
    margin-bottom: 32px;
    border-radius: 20px;
    box-shadow: 1px 2px 7px #3545960d;
}

.assessment-criteria-title {
    color: #354596;
    font-size: 20px;
    font-weight: 600;
}

.assessment-criteria-description {
    margin-bottom: 16px;
    font-size: 20px;
    color: #354596;
    text-align: center;
}

.assessment-criteria-radio-control {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.assessment-criteria-radio-control > label {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 24px;
    margin: 0 2px;
    border-radius: 10px;
    background-color: #f7fcff;
    box-shadow: 1px 2px 7px #00000012;
    cursor: pointer;
}