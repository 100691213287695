@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


html,
body {
  font-family: 'Inter', sans-serif;
}

body {}

label {
  display: block;
  margin-top: 10px;
}

select {
  cursor: pointer;
}

div,
p {
  color: #354596;
}

.login-card {
  width: 561px;
  padding: 56px 80px;
  margin: 64px auto 0;
  border-radius: 20px;
  box-shadow: 1px 2px 7px #3545960d;
}

.login-title {
  margin-bottom: 36px;
  font-weight: 500;
  font-size: 36px;
  line-height: 32px;
  text-align: center;
  color: #5CA0F5;

}

.login-label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #354596;
}

.login-label>span {
  color: #f25454;
}

.login-input {
  padding-left: 24px;
  height: 59px;
  border: 0;
  border-radius: 50px;
  border: 1px solid #c1e9ff;
  background-color: #f7fcff;
  font-size: 16px;
  font-weight: 500;
  color: #354596;
  transition: all .3s;
}

.login-input:focus {
  box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, .25);
  font-size: 16px;
  color: #354596;
}

.login-alert {
  margin-top: 10px;
  background-color: transparent;
}

.alert {
  padding: 0;
  margin: 8px 0;
  font-size: 14px;
  background-color: transparent;
  color: #f25454;
  border: 0;
}

.btn {
  display: flex;
  justify-content: center;
  border: 0;
  border-radius: 50px;
  background-color: #5ca0f5;
  font-size: 16px;
  color: #FFFFFF;
  transition: all .3s;
  cursor: pointer;
}

.btn>img {
  max-height: 14px;
}

.btn:disabled {
  background-color: #a8a8a8;
  color: #FFFFFF;
  cursor: not-allowed;
}

.btn:hover {
  background-color: #124c92;
  color: #FFFFFF;
}

.btn-login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 40px;
  margin-top: 40px;
}

.nav-btn {
  padding: 4px 24px;
}

.nav-btn:hover {
  background-color: #ffad00;
  color: #ffffff;
}


.nav-btn-login {
  border: 1px solid #ffad00;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  color: #ffad00;
}

.nav-btn-logout {
  border: 1px solid #ffad00;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  color: #ffad00;
}

.signup-roles {
  padding: 8px 8px 24px 8px;
  border-radius: 20px;
  background-color: #f7fcff;
  border: 1px solid #c1e9ff;
}

.signup-roles>label {
  padding: 4px 8px;
  border-radius: 20px;
  cursor: pointer;
}

.signup-roles>label>input {
  margin-right: 8px;
}

.container {
  min-height: 70vh;
  margin: 42px auto 54px;
}


.bg-main {
  background-color: #f7fcff;
}

.all-stories-control {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px 0;
  user-select: none;
  cursor: pointer;
}

.all-stories-control h2 {
  font-size: 24px;
  color: #5ca0f5;
}

.all-stories-control-active {
  border-bottom: 1px solid #354596;
}

.all-stories-control-active h2 {
  color: #354596;
}