.feedback {
    margin-bottom: 44px;
}

.feedback-title {
    font-size: 20px;
    font-weight: 600;
    color: #354596;
    margin-bottom: 12px;
}

.feedback div {
    position: relative;
}

.feedback-length {
    position: absolute;
    width: 80px;
    top: -32px;
    right: 0;
    padding: 4px 8px;
    border-radius: 10px;
    box-shadow: inset -1px -1px 5px rgba(53, 69, 150, 0.1), inset 1px 1px 5px rgba(53, 69, 150, 0.1);
    font-size: 12px;
    font-weight: 600;
    text-align: right;
}

.feedback-textarea {
    min-height: 80px;
    padding: 36px 24px 24px;
    box-shadow: inset -1px -1px 5px rgba(53, 69, 150, 0.1), inset 1px 1px 5px rgba(53, 69, 150, 0.1);
    border-radius: 20px;
    overflow: hidden;
    resize: none;
}