.comment-input {
    position: relative;
}

.comment-input-length-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}

.comment-input-length {
    padding: 8px 16px;
    background-color: #f7fcff;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
}

.comment-input-textarea {
    width: 100%;
    padding: 16px;
    margin-bottom: 12px;
    border: 0;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 0px #3545961a inset, -1px -1px 5px 0px #3545961a inset;
    resize: none;
    transition:  background-color .3s;
}

.comment-input-textarea:focus {
    outline: 0;
    background-color: #f8fbff;
}

.comment-input-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}

.comment-input-btn {
    display: inline-flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 50px;
    background: #ffffff;
    box-shadow: 1px 1px 5px 0px #3545961a inset, -1px -1px 5px 0px #3545961a inset;
}

.comment-input-btn > img {
   padding-left: 8px;
}