.unpassed-stories-page .unpassed-stories-page-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
}

.unpassed-stories-page h1 {
    font-size: 32px;
    margin-bottom: 0;
}

.unpassed-stories-page-title div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    margin-left: 12px;
    border-radius: 20px;
    background-color: #354596;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}

.unpassed-stories-page table {
    table-layout: fixed;
    font-size: 14px;
}

.unpassed-stories-page table tr>* {
    color: #354596;
    vertical-align: middle;
}

.unpassed-stories-page table tr>*:is(:nth-child(3), :nth-child(4), :nth-child(6)) {
   text-align: center;
}

.unpassed-stories-page table tr>*:is(:nth-child(7)) {
    text-align: right;
 }

.unpassed-stories-page table thead tr {
    border-bottom: 1px solid #354596;
}

.unpassed-stories-page table tbody tr {
    border-bottom: 1px solid #fafafa;
}

.unpassed-stories-page .btn {
    display: inline-flex;
    font-size: 14px;
}

.unpassed-stories-page .read-comments-btn {
    color: #354596;
    background-color: transparent;
    text-decoration: underline;
}

.unpassed-stories-page .read-comments-btn:hover {
    color: #5ca0f5;
    background-color: transparent;
}