.story-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 64px;
    margin-bottom: 64px;
}

.story-nav>a {
    flex: 1 0 0;
    display: flex;
    justify-content: center;
    padding: 16px;
    border-radius: 30px;
    color: #5ca0f5;
    text-align: center;
    text-decoration: none;
}

.story-nav>a.active {
    background-color: #f7fcff;
}

@media (max-width: 768px) {
    .story-nav {
        flex-direction: column;
        gap: 12px;
    }
}