.categories-left {
    display: flex;
    flex-direction: column;
    row-gap: 44px;
}

.categories-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 44px;
}