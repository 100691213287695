.evaluations {
    min-height: 90vh;
}

.evaluations-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 36px;
}

.evaluations-header h2 {
    color: #44aae0;
    font-size: 32px;
    font-weight: 500;
}

.evaluations-header div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffad00;
    font-size: 24px;
    font-weight: 600;
}

.evaluations-header span {
    margin-right: 8px;
    color: #354596;
    font-size: 16px;
    font-weight: 500;
}

.evaluations .criterions {
    margin-bottom: 12px;
}

.evaluations .criterions>div:last-child {
    margin-bottom: 0px;
}