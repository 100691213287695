.story-control h1 {
    margin: 0 0 32px 0;
    font-size: 36px;
    font-weight: 500;
    color: #44aae0
}

.story-control h2 {
    margin: 14px 0 7px 0;
    font-size: 24px;
    font-weight: 600;
    color: #354596;
}

.story-control h3 {
    margin: 8px 0 8px 0;
    font-size: 18px;
    font-weight: 600;
    color: #354596;
}

.story-control textarea {
    resize: none;
}

.assessors {
    min-height: 200px;
}

.select {
    user-select: none;
}

.control img {
    width: 28px;
    filter: invert(1);
}

.control .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 1px 2px 7px #3545960d;
    font-size: 14px;
}