.rating-scale-title {
    margin-bottom: 20px;
    color: #354596;
    font-size: 20px;
    font-weight: 600;
}

.rating-scale-content {
    padding: 32px;
    margin-bottom: 32px;
    border: 1px solid #c1e9ff;
    border-radius: 20px;
}

.rating-scale-content>p {
    margin-bottom: 12px;
    color: #354596;
    font-size: 16px;
    font-weight: 400;
}

.rating-scale-content>p:last-child {
    margin-bottom: 0;
}

.rating-scale-content>p>span {
    font-weight: 600;
}