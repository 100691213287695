.stories-table-wrapper h2 {
    margin: 24px 0;
    font-size: 32px;
}

.stories-table-wrapper table {
    font-size: 14px;
}

.stories-table-wrapper table thead tr {
    border-bottom: 1px solid #354596;
}

.stories-table-wrapper table tbody tr {
    border-bottom: 1px solid #fafafa;
}

.stories-table-wrapper table tr > * {
    color: #354596;
    vertical-align: middle;
}

.stories-table-wrapper table[data-story-type="about-teacher-stories"] tr > *:is(:nth-child(2), :nth-child(6), :nth-child(7)) {
    text-align: center;
}

.stories-table-wrapper table[data-story-type="teacher-stories"]  tr > *:is(:nth-child(2), :nth-child(3), :nth-child(6), :nth-child(7), :nth-child(8), :nth-child(9), :nth-child(10)) {
    text-align: center;
}

.stories-table-wrapper table tr > * > a {
    font-size: 14px;
}