.category-page h1 {
    margin-bottom: 24px;
    color: #5ca0f5;
    font-size: 36px;
}

.category-page .category-page-description {
    margin-bottom: 32px;
    font-size: 18px;
}

.category-page .category-page-table {
    table-layout: fixed;
    font-size: 14px;
}

.category-page .category-page-table thead tr {
    border-bottom: 1px solid #354596;
}

.category-page .category-page-table tbody tr {
    border-bottom: 1px solid #fafafa;
}

.category-page .category-page-table tr>* {
    vertical-align: middle;
    color: #354596;
}

.category-page .category-page-table tr>*:is(:nth-child(3), :nth-child(4), :nth-child(5)) {
    text-align: center;
}

.category-page .category-page-table tr>*:is(:nth-child(6)) {
    text-align: right;
}

.category-page .category-page-table tr td>a {
    display: inline-flex;
    min-width: 150px;
}

.category-page .category-page-table {
    table-layout: auto;
}

.category-page .category-page-table-admin tr td>a {
    min-width: auto;
    font-size: 14px;
}

.category-page .category-page-table-admin tr>*:is(:nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6)) {
    text-align: center;
}

.category-page .category-page-table-admin tr>*:is(:nth-child(7)) {
    text-align: right;
}

.category-page .checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.category-page .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.category-page .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

.category-page .checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

.category-page .checkbox-container input:checked~.checkmark {
    background-color: #354596;
}

.category-page .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.category-page .checkbox-container input:checked~.checkmark:after {
    display: inline-block;
}

.category-page .checkbox-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.category-page-moderators-list {
    margin-bottom: 12px;
}

.category-page-assessors-list {
    margin-bottom: 36px;
}