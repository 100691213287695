.unverified-stories-page .unverified-stories-page-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
}

.unverified-stories-page h1 {
    font-size: 32px;
    margin-bottom: 0;
}

.unverified-stories-page-title div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    margin-left: 12px;
    border-radius: 20px;
    background-color: #354596;;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}

.unverified-stories-page table {
    table-layout: fixed;
    font-size: 14px;
}

.unverified-stories-page table tr>* {
    color: #354596;
    text-align: center;
}

.unverified-stories-page table tr>*:first-child {
    width: 10%;
    text-align: left;
}

.unverified-stories-page table tr>*:is(:nth-child(2)) {
    text-align: left;
 }


.unverified-stories-page table tr>*:last-child {
    width: 10%;
    text-align: right;
}

.unverified-stories-page table thead tr {
    border-bottom: 1px solid #354596;
}

.unverified-stories-page table tbody tr {
    border-bottom: 1px solid #fafafa;
}

.unverified-stories-page .btn {
    display: inline-flex;
    font-size: 14px;
}