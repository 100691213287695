.evaluation>h3 {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-radius: 20px;
    color: #354596;
    user-select: none;
    cursor: pointer;
    transition: all .3s;
}

.evaluation>h3:hover {
    background-color: #f7fcff;
}

.evaluation>h3>img {
    width: 30px;
    transition: transform .5s;
}

.evaluation-arrow {
    transform: rotate(0);
}

.evaluation-arrow-rotate {
    transform: rotate(-180deg);
}

.evaluation-footer {
    display: flex;
    justify-content: flex-end;
}

.evaluation-footer>div {
    color: #354596;
    font-size: 16px;
    font-weight: 500;
}

.evaluation-footer>div>span {
    padding-left: 8px;
    color: #ffad00;
    font-size: 24px;
    font-weight: 600;
}